import React,{Component} from 'react';
import {Row, Col,Card,Container, Button} from 'react-bootstrap'
import { base_url } from '../../globalConstants'
import axios from 'axios';
import { saveAs } from 'file-saver';
import Axios from "axios";
import M from "materialize-css";

class ReportsOneClick extends Component{
    constructor(props){
        super (props);

        this.state = {
            countryName: '',
            country_list: this.props.countryList,
			selecItem:[]
        }

        // console.log('selectCounrtyOptions',this.state.selectCounrtyOptions)

    }
	

  handleChange = e => {
	  	//const selecItem = [];

  if (e.target.checked)
    {
    this.state.selecItem.push(e.target.value);
  }
  else
  {
    for( var i = 0; i < this.state.selecItem.length; i++){ 
                                   
        if ( this.state.selecItem[i] === e.target.value) { 
            this.state.selecItem.splice(i, 1); 
            i--; 
        }
    }
  }
  console.log("arra:" + this.state.selecItem.toString());
  }
	
	invoicemissing = () => {
	  const  emaildata= {"type" : "IM","names" : this.state.selecItem.toString()}
	  try{
		  Axios({
			  method:'post',
			  url: base_url+'send_notification_to_all',
			  data: emaildata,
			}).then(
			  function(response){ 
				console.log(response)
				console.log("Send notification: invoice missing done");
				M.toast({ html: 'Send notification: invoice missing', classes: "white-text green rounded" })
			  }
			).catch(
			  function(response){ 
				console.log(response)
				console.log("Send notification: invoice missing error");
				M.toast({ html: 'Send notification: invoice missing error', classes: "white-text red rounded" })
			  }
			)
	  }
	  catch(error){
		  console.log(error)
		  M.toast({ html: 'Send notification: invoice missing error', classes: "white-text red rounded" })
	  }

  }
  
  waitingforconso = () => {
	  	  const  emaildata= {"type" : "WFC","names" : this.state.selecItem.toString()}
	  try{
		  Axios({
			  method:'post',
			  url: base_url+'send_notification_to_all',
			  data: emaildata,
			}).then(
			  function(response){ 
				console.log(response)
				console.log("Send notification: waiting for consolidation done");
				M.toast({ html: 'Send notification: waiting for consolidation', classes: "white-text green rounded" })
			  }
			).catch(
			  function(response){ 
				console.log(response)
				console.log("Send notification: waiting for consolidation error");
				M.toast({ html: 'Send notification: waiting for consolidation error', classes: "white-text red rounded" })
			  }
			)
	  }
	  catch(error){
		  console.log(error)
		  M.toast({ html: 'Send notification: waiting for consolidation error', classes: "white-text red rounded" })
	  }
  }
  
  

    handleCountryChange = e => {
        console.log(e.target.value);
        this.setState({countryName: e.target.value })
    }
    
    downloadReport = async () => {
        let report_url = base_url + `one_click_order_csv?country=${this.state.countryName}`
        console.log('url',report_url);
        console.log('this.state.countryName',this.state.countryName);
        var FileSaver = require('file-saver');

        await axios({
            url: report_url,
            method: 'GET',
            responseType: 'arraybuffer'
        })
        .then((response)=>{
            {
                var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                FileSaver.saveAs(blob, `${this.state.countryName}.xlsx`);
            }
            console.log('response',response);
        }).catch((err)=>{
            console.log('err',err);
        })
    }

    render(){
        const selectCounrtyOptions = this.state.country_list.map((country, index) => {

            return (
              <option key={index} value={country}>{country}</option>
            )
          })
        return(
            <Container>
			    <Row>
                    <Col>
                        <div className="nav-wrapper  "><p className="left orange-text ">
                            <h3>Pending Orders Report</h3></p>
                        </div>
                    </Col>
                </Row>
            <Row>
                <Col>
                    <h5>Countrywise</h5> <br/>
                </Col>
              {/* <Col >
                <div>
                    <h5>Select country</h5>
                </div>
              </Col> */}
              <Col >
                <div>
                    <select id="country_name_select" name="country_name" className="browser-default country_name_select" value={this.state.countryName} onChange={this.handleCountryChange}>
                        <option value=""  >Choose Country</option>
						<option value="ALL"  >ALL</option>
                        {selectCounrtyOptions}
                    </select>
                </div>
              </Col>
              <Col>
                <Button onClick={this.downloadReport}>
                    Download
                </Button>
				
              </Col>
			  
            </Row>

<Row>
			<Col>
			<div className="container">
			
				<div className="card">
					<div className="card-header">
					  <p className="title">Select Country </p>
					</div>
				</div>
				
				
				<div className="card-body">
				  {this.state.country_list.map((item, index) => {
					return (
					  <div key={item} className="checkbox-container">
						<label><input
						  type="checkbox"
						  name="languages"
						  value={item}
						  onChange={this.handleChange}
						/>
						<span>{item}</span></label>
					  </div>
					);
				  })}
				</div>
				
				
				</div>
				</Col>
					
					
				<Col>
				<Button variant="outline-secondary " className="indigo white-text mr-2" onClick={this.invoicemissing}>INVOICE MISSING </Button>
				</Col>
			  
			  <Col>
			  			  
			  <Button variant="outline-secondary " className="orange white-text mr-2" onClick={this.waitingforconso}>WFC </Button>
			  </Col>
			  
			</Row>
			
            </Container>
        )
    }
}

export default ReportsOneClick;